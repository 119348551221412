<template>
<div>
	<TopNav></TopNav>
	<Header></Header>
	
	<div class="BGWhite" v-if="Goods == null">
		<div style="line-height: 500px;text-align: center;">
			正在读取商品
		</div>
	</div>
	
	<div class="ShopTitle" v-if="Goods != null">
		
		<div class="Bg">
			<img src="../../assets/img/shop/titlebg2.png" />
		</div>
		
		<div class="Wrap Title">
			
			<ul>
				
				<a v-if="Goods.ShopLogo.length > 0" class="Logo" :href="'/#/shop/'+Goods.ShopId" target="_blank">
					<img :src="Goods.ShopLogo" />
				</a>
				
				<div class="Info">
					<h2><a style="color: rgba(255,255,255,0.7);" :href="'/#/shop/'+Goods.ShopId" target="_blank">{{Goods.ShopName}}</a></h2>
					<li v-if="Goods.ShopDescription && Goods.ShopDescription.length > 0"><i class="el-icon-bell"></i> 店铺介绍:{{Goods.ShopDescription}}</li>
				</div>

				
				<div class="RightAct">
					<form class="ShopSearch" :action="'/#/shop/'+Goods.ShopId+'?keywords='+ShopSearchKeywords" method="get" target="_blank">
						<span>
							<input v-model="ShopSearchKeywords" class="ShopSearchInput" />
						</span>
						<i>
							<button class="ShopSearchBTn"><i class="el-icon-search"></i> 店内搜索</button>
						</i>
					</form>
				</div>
				
			</ul>
			
		</div>
		
		
	</div>
	
	<div class="BGWhite" v-if="Goods != null">
		<div class="Wrap">
			
			<div class="Guide"> <span @click="$Jump('/goods/goods_list')" style="cursor: pointer;">所有分类</span> <em>></em> <span @click="$Jump('/goods/goods_list?c1='+Goods.CategoryOne)" style="cursor: pointer;">{{Goods.CategoryOneName}}</span><em>></em> 商品详情</div>
			
		</div>
	</div>
	
	<div class="BGWhite PaddingBottom20" v-if="Goods != null">
		<div class="Wrap">
			
			<div class="GoodsInfo">
				
				<div class="Preview">
					<GoodsPreview :images="Goods.PicWall"></GoodsPreview>
				</div>
				
				<div class="Fields">
					<li>
						<h1 class="Title">
							{{Goods.Title}}
						</h1>

						
						<span class="Favorite">
							
							<el-button @click="FavoriteChange()" round size="mini" :icon="IsFavorite ? 'el-icon-star-on':'el-icon-star-off'">
								{{IsFavorite ? '取消收藏':'收藏'}}
							</el-button>
							
						</span>
						
					</li>
					<li class="Code" style="display: flex;">
						<em style="flex: 1;">
							<template v-if="Goods != null && Goods.SourceSn != ''">
								商品编码： {{Goods.SourceSn}}
							</template>
						</em>
						
						<em>
							<a class="ShopName" :href="'/#/shop/'+Goods.ShopId" target="_blank"><label class="el-icon-s-shop" style="width: 14px;margin: 0px;padding: 0px;margin-right: 3px;"></label>{{Goods.ShopName}}</a>
						</em>
					</li>
					<li class="MarginBottom10 MarginTop10">
						<span class="Price" style="display: flex;align-items: center;">
							￥{{Price}} <em v-if="Goods != null && Goods.IsPt === 1" style="color: rgba(248,137,137,1);font-size: 0.9rem;margin-left: 10px;font-weight: bold;">拼团价:￥{{PricePt}},成团仅需{{Goods.PtMembers}}人</em>
						</span>
					</li>
					
					<li v-for="(spec,specI) in SpecList" :key="specI">
						<label>{{spec.Name}}</label>
						<div class="Attrs">
							<template v-for="(val,valI) in spec.Vals">
								
								<em v-if="val.IsSku" @click="SpecClick(specI,val.Id,true)" :key="valI" :class="val.IsClick ? 'On':'' ">{{val.Name}}</em>
								
								<em v-else :key="valI" class="Disable">{{val.Name}}</em>
								
							</template>
						</div>
					</li>

	
					<li>
						<label>数量</label>
						<div>
							<i style="display: inline-block;width: 80px;">
								<el-input-number v-model="BuyNum" size="mini" :min="1"></el-input-number>
							</i>
						</div>
					</li>
					
					<li class="MarginTop10" v-if="Goods != null && Goods.Stock > 0">
						<label></label>
						<div>
						
						<el-button icon="el-icon-shopping-bag-1" v-if="Goods.IsPt === 1" type="danger" @click="Pin()">发起拼团</el-button>
						
						<el-button icon="el-icon-goods" type="success" @click="AddToCart('buy')">立即购买</el-button>
						
						<el-button icon="el-icon-shopping-cart-2"  @click="AddToCart('cart')">加入购物车</el-button>
							
						</div>
					</li>
					<li class="MarginTop10" v-else>
						<label></label>
						<div>
							<el-button type="infor" :disabled="true">已经卖光</el-button>
						</div>
					</li>
					
				</div>
				
			</div>
			
		</div>
	</div>

<div class="BGWhite MarginTop20" v-if="Goods != null">
	<div class="Wrap">
		<div class="Detail">
			
			<div class="Nav">
				
				<em :class="ShowType == 'recommend' ? 'On':''" @click="ShowType = 'recommend'">推荐商品</em>
				<span>|</span>
				<em :class="ShowType == 'description' ? 'On':''" @click="ShowType = 'description'">商品详情</em>
				
				<span>|</span>
				<em :class="ShowType == 'props' ? 'On':''" @click="ShowType = 'props'">商品评论</em>
			</div>
			
			<div class="GoodsList" v-show="ShowType == 'recommend'">
				
				<a :href="'/#/goods/detail/'+item.Id" target="_blank" class="GoodsOne" v-for="(item,itemI) in RecommendGoods" :key="itemI">
					<img :src="item.Thumb" />
					<li>
						{{item.Title}}
					</li>
					<span class="Price">￥<em>{{item.Price}}</em></span>
					<span class="Compare">去购买</span>
					
					<span class="BR"></span>
				</a>
				
				<div class="More" @click="$Jump('/goods/goods_list')">
					<span>发现更多</span>
				</div>
				
			</div>
			
			<ul class="Show" v-show="ShowType == 'description'">
				
				<div v-if="Goods.Detail.length < 6" style="height: 200px;line-height: 200px;text-align: center;font-size: 1.3rem;">
					<i class="el-icon-loading"></i>正在加载商品详情...
				</div>
				<div v-html="Goods.Detail">
				</div>
				
			</ul>
	
			<div class="Props" v-show="ShowType == 'props'">
				
				<h3>交易评论</h3>

				
				<ul v-for="comment in Comments" :key="comment.Id">
					<li>
						<span class="uname">{{comment.BuyerName}}</span>
				
						<span class="ustar"><el-rate disabled v-model="comment.Score"></el-rate></span>
						<span class="utime">{{comment.CreatedAt}}</span>
					</li>
					<p>
						<i style="font-style: normal;padding-right: 10px;color: rgba(0,0,0,0.4);" v-if="comment.SkuName.length > 0">[规格:{{comment.SkuName}}]</i>{{comment.Content}}
					</p>
				</ul>
				
				<ul v-if="Comments == null || Comments.length == 0">
					<li>
						<span class="uname">当前暂无评论</span>
					</li>
				</ul>
				
				
			</div>	
			
		</div>
	</div>
</div>

	<Footer></Footer>
	
</div>
</template>

<script>
	import GoodsPreview from "@/components/goods/GoodsPreview.vue"
	import {InputNumber,Rate} from 'element-ui'
	export default {
	  name: 'GoodsInfo',
	  props: {
	  },
	  data() {
	      return {
			  ShopSearchKeywords:'',
			  ShowType:'recommend', //description详情，props 规格，recommend 规格
			  Goods:null,
			  BuyNum:1,
			  Price:0.00,
			  PricePt:0.00,//拼团价
			  SkuList:[],
			  SpecList:[],
			  SelectedSpecs:[],//记录每一类属性被选中的值（属性值id）
			  SelectedSkuId:'',
			  IsFavorite:false,
			  RecommendGoods:[],//推荐商品
			  IsRecommend:false,//是否已经拉取过推荐商品
			  ShowBuyBtn:false,
			  Comments:[],//商品评论
			  PtId:"",//拼团订单
	      }
	    },
	  components: {
		  GoodsPreview,
		  "el-input-number":InputNumber,
		  'el-rate':Rate,
	  },
	  computed:{
		NewGoodsId(){
			return this.$route.params.Id
		}  
	  },
	  watch:{
		  NewGoodsId(newVal,oldVal){
			  if(newVal != '' && oldVal != ''){
				  this.Goods = null
				  this.BuyNum = 1
				  this.Price = 0.00
				  this.SkuList = []
				  this.SpecList = []
				  this.SelectedSpecs = []
				  this.SelectedSkuId = ''
					this.GetGoods(newVal)
				
			  }
		  }
	  },
	  created() {
		  this.$router.afterEach((to,from,next) => {
		    window.scrollTo(0,0);
		  });
		  this.GetGoods(this.$route.params.Id)
		  this.GetRecommend()
	  },
	  methods:{
		  GetComments(){
		  		
		  	
		  		  //发起网络请求，添加购物清单
		  		  let data = {
		  			Service:'Goods',
		  			Class: 'GoodsComment',
		  			Action: 'List',
					GoodsId:this.Goods.Id,
					Page:1,
					PageSize:30,
					Status:50,
		  		  }
		  		  
		  		  this.$post(this.$store.getters.getApiHost,data)
		  		  .then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
		  			this.Comments = res.Data.GoodsCommentList
		  			
		  		  })
		  },
		  Pin(){//创建拼团
			  
			  if(this.SkuList.length > 0 && this.SelectedSkuId == ''){
			  	this.$message('请选择可售商品的属性')
			  	return
			  }
			  
			  if(this.BuyNum < 1){
			  	this.$message('最小购物数量为1')
			  	return
			  }
			  
			  let data = {
			  	Service:'Goods',
			  	Class: 'GroupBargaining',
			  	Action: 'Add',
			  	GoodsId:this.Goods.Id
			  }
			  
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
				this.PtId = res.Data.Id
			  	this.AddToCart('buy',1)
			  	
			  })
			  
		  },
			AddToCart(_btnType,_isPt = 2){
				
				if(this.SkuList.length > 0 && this.SelectedSkuId == ''){
					
					this.$message('请选择可售商品的属性')
					return
				}
				
				if(this.BuyNum < 1){
					this.$message('最小购物数量为1')
					return
				}
				
				
				//发起网络请求，添加购物清单
				let data = {
					Service:'Goods',
					Class: 'ShoppingCart',
					Action: 'Add',
					GoodsId:this.Goods.Id,
					SkuId:this.SelectedSkuId,
					Num:this.BuyNum,
					IsPt:_isPt,
					PtId:this.PtId,
				}
				
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					
					if(_btnType == 'cart'){
						this.$message("购物车添加成功")
						this.GetCartCount()
						return
					}
					
					if(_btnType == 'buy'){ //直接购买模式
						
						//通过购物ID跳转
						this.GetCartCount(res.Data.Id)
						
					}
					
				})
				
			},
			GetCartCount(_jumpCartId){
					
					if(this.$store.getters.getToken == ''){
						return
					}
					  //发起网络请求，添加购物清单
					  let data = {
						Service:'Goods',
						Class: 'ShoppingCart',
						Action: 'Count',
						IsPt:2,
					  }
					  
					  this.$post(this.$store.getters.getApiHost,data)
					  .then((res) => {
						
						if(res.ErrorId != 0){
							this.$message(res.Msg)
							return
						}
						
						if(res.Data.Count > 0){
							this.$store.dispatch('setCartItems',res.Data.Count)
						}else{
							this.$store.dispatch('setCartItems',0)
						}
						
						if(_jumpCartId != undefined && _jumpCartId != null){
							this.$Jump('/buy/'+_jumpCartId)
						}
						
					  })
			},
			SpecClick(_index,_specValId,_isUserClick = false){
				
				//如果点击的是苏宁商品，拦截跳转
				if(this.Goods.Source == 'sn' && _isUserClick){
					//点击后跳转到相关sku页面
					//取出当前点击的属性ID
					 let that = this
					this.SkuList.forEach(function(sku){
					   if(sku.AttrValIds[0] == _specValId){
							that.$Jump('/goods/detail/sn_'+sku.Id)
							return
					   }
					})
					
				}
				
				this.Price = this.Goods.Price
				this.PricePt = this.Goods.PricePt
				this.SelectedSkuId = ''
				
				//判断当前属性是否与被选属性在同一组，如果是，则替代，否则插入
				if(this.SelectedSpecs.length == 0){
					
					this.SelectedSpecs.push(_specValId) //将属性插入被选属性列表
					
				}else{
					
					let isReplace = false
					for(var e=0;e<this.SpecList.length;e++){
						
						let specValsStr = JSON.stringify(this.SpecList[e].Vals)
						if(specValsStr.indexOf(_specValId) == -1){
							continue
						}
						//查找当前被选属性组中是否有成员存在于本组
						for(var b=0;b<this.SelectedSpecs.length;b++){
							if(specValsStr.indexOf(this.SelectedSpecs[b]) != -1){
								//换值
								this.SelectedSpecs[b] = _specValId
								this.SelectedSpecs = JSON.parse(JSON.stringify(this.SelectedSpecs))
								isReplace = true
								break
							}
						}
						
					}
					
					if(!isReplace){
						this.SelectedSpecs.push(_specValId)
					}
					
				}

				//属性按钮恢复出厂设置
				this.SpecValBtnInit()
				
				//重新设置其它属性的可选属性,将部分SKU为空的组合设定为灰色(只有多重属性需此项)
				if(this.SpecList.length > 1){
						
					let SkuList = JSON.parse(JSON.stringify(this.SkuList))
					let SpecList = JSON.parse(JSON.stringify(this.SpecList))
					
					//分析属性，完成赋值，判断其可关联出Sku的所有其它属性,用以颜色变灰处理
					for(var i=0;i<SpecList.length;i++){
						
						let spec = SpecList[i]
						let specValsStr = JSON.stringify(spec.Vals)
							
						//遍历所有子元素，判断其是否和当前被选元素产生了sku重叠
						for(var j=0;j<spec.Vals.length;j++){
							
							let specValId = spec.Vals[j].Id
						
							let isGotSku = false
							for(var m=0;m<this.SkuList.length;m++){
								////SKU必须全部满足已选属性值以及当前正在遍历的属性值
								//找到SKU匹配的属性值id并且库存大于0
								if(this.SkuList[m].Stock == 0){
									continue
								}
								//查看是否能找到当前属性
								let skuAttrValIds = JSON.stringify(this.SkuList[m].AttrValIds)
								if(skuAttrValIds.indexOf(specValId) == -1){
									continue
								}
								
								//查看是否能找到被选中的所有属性
								let matchTimes = 0
								for(var h=0;h<this.SelectedSpecs.length;h++){
									
									//如果跟被选中的元素在同组属性，则默认可匹配，因同组无需互匹
									//判断当前遍历的被选属性值id是否跟当前元素在同一个属性组
									if(specValsStr.indexOf(this.SelectedSpecs[h]) != -1){
										matchTimes += 1
										continue
									}
									
									if(skuAttrValIds.indexOf(this.SelectedSpecs[h]) != -1){
										matchTimes += 1
									}
									
								}
								//如果当前SKU属性不能贴合已选的所有属性值，则失败
								if(matchTimes != this.SelectedSpecs.length){
									continue
								}
								isGotSku = true
							}
							SpecList[i].Vals[j].IsSku = isGotSku
						}
						
					}
					this.SpecList = JSON.parse(JSON.stringify(SpecList))
					
				}
				
				//修改显示价格，并锁定Sku
				if(this.SelectedSpecs.length == this.SpecList.length){
					
					for(var t=0;t<this.SkuList.length;t++){
						
						let sku = this.SkuList[t]
						if(sku.Stock == 0){
							continue
						}
						//取出SKU的属性值ID，判断跟当前选择属性的可匹配次数
						//查看是否能找到被选中的所有属性
						let matchTimes = 0
						let skuAttrValIds = JSON.stringify(sku.AttrValIds)
						for(var y=0;y<this.SelectedSpecs.length;y++){
							if(skuAttrValIds.indexOf(this.SelectedSpecs[y]) != -1){
								matchTimes += 1
							}
						}
						//如果当前SKU属性不能贴合已选的所有属性值，则失败
						if(matchTimes != this.SelectedSpecs.length){
							continue
						}
						
						//锁定成功
						this.SelectedSkuId = sku.Id
						this.Price = sku.Price
						this.PricePt = sku.PricePt
						
					}
					
					
					
				}	
				
				//修改被选中的显示属性
				let SpecList2 = JSON.parse(JSON.stringify(this.SpecList))
				for(var c=0;c<SpecList2.length;c++){
					for(var v=0;v<SpecList2[c].Vals.length;v++){
						for(var z=0;z<this.SelectedSpecs.length;z++){
							if(this.SelectedSpecs[z] == SpecList2[c].Vals[v].Id){
								SpecList2[c].Vals[v].IsClick = true
							}
						}
					}
				}
				this.SpecList = JSON.parse(JSON.stringify(SpecList2))
				
			},
			GetGoods(_id,_times = 1){
				
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Goods',
		  			Action: 'Get',
		  			Id:_id,
					LoadingText:'none'
		  		}
		  		
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.Goods = res.Data
					this.Price = this.Goods.Price
					this.PricePt = this.Goods.PricePt
					
					this.GetSpecsAndSkus(this.Goods.AttrList,this.Goods.SkuList)
					if(this.$store.getters.getToken != ''){
						this.FavoriteCheck()
					}
					
					this.ShowType = 'description'
					
					this.GetComments()

		  		})
			},
			GetSpecsAndSkus(_attrs,_skus){

				this.SkuList = _skus
				
				this.SkuList = JSON.parse(JSON.stringify(this.SkuList))
				
				this.SpecList = _attrs
				this.SpecValBtnInit()
				
				//初始化被选属性
				this.SelectedSkuId = ''
				this.SelectedSpecs = []
				
			},
			SpecValBtnInit(){ //将商品属性值按钮设置为出厂状态
				
				let SpecList = JSON.parse(JSON.stringify(this.SpecList))
				
				//分析属性，完成赋值，判断其可关联出Sku的所有其它属性,用以颜色变灰处理
				for(var i=0;i<SpecList.length;i++){
					//遍历当前属性的所有属性值
					//console.log('遍历属性',SpecList[i].Id,"共有值",SpecList[i].Vals.length)
					for(var j=0;j<SpecList[i].Vals.length;j++){
						//console.log('遍历属性值',SpecList[i].Vals[j].Id)
						let specValId = SpecList[i].Vals[j].Id
						let isGotSku = false
						for(var m=0;m<this.SkuList.length;m++){
							//找到SKU匹配的属性值id并且库存大于0
							let skuAttrValIds = JSON.stringify(this.SkuList[m].AttrValIds)
							if(skuAttrValIds.indexOf(specValId) != -1 && this.SkuList[m].Stock > 0){
								isGotSku = true
							}
						}
						SpecList[i].Vals[j].IsSku = isGotSku
						SpecList[i].Vals[j].IsClick = false
					}
				}
				
				this.SpecList = JSON.parse(JSON.stringify(SpecList))
	
				
			},
			FavoriteCheck(){
				
				if(this.$store.getters.getToken == ''){
					return
				}
				
				let data = {
					Service:'Goods',
					Class: 'GoodsFavorite',
					Action: 'Check',
					GoodsId:this.Goods.Id,
					LoadingText:'none'
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					if(res.ErrorId != 0){
						return
					}
					this.IsFavorite = res.Data.IsFavoriteCheck
				
				})
				.catch(function (response) {
					this.$message('网络请求错误')
				})
			},
			FavoriteChange(){
				let data = {
					Service:'Goods',
					Class: 'GoodsFavorite',
					Action: 'Add',
					GoodsId:this.Goods.Id,
				}
				if(this.IsFavorite){
					data.Action = 'Del'
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					if(this.IsFavorite){
						this.$message('已取消收藏该商品')
						this.IsFavorite = false
					}else{
						this.$message('商品收藏成功')
						this.IsFavorite = true
					}
				
				})
				.catch(function (response) {
					this.$message('网络请求错误')
				})
			},
			GetRecommend(){
			
				this.IsRecommend = true
				
				let data = {
					Service:'Goods',
					Class: 'Goods',
					Action: 'List',
					Page:1,
					PageSize:20,
					Star:5,
					LoadingText:'none'
				}
				
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					if(res.ErrorId != 0){
						this.$message(res.Msg)
						return
					}
					this.RecommendGoods = res.Data.GoodsList
					
				})
			},
	  }
	}
</script>

<style  scoped>
	.Guide{
		margin-top: 15px;
		margin-bottom: 15px;
		color: rgba(0,0,0,0.4);
	}
	.Guide em{
		color: rgba(0,0,0,0.2);
		margin-left: 10px;
		margin-right: 10px;
	}
	
	.GoodsInfo{
		display: flex;
		width: 100%;
	}
	.GoodsInfo .Preview{
		width: 450px;
		margin-right: 20px;
	}
	.GoodsInfo .Fields{
		flex: 1;
		padding: 5px 25px 5px 25px;
		border: 1px solid rgba(0,0,0,0.05);
	}
	.GoodsInfo .Fields li{
		display: flex;
		align-items: center;
		padding: 10px 0px;
		border-bottom: 1px solid rgba(0,0,0,0.03);
		
	}
	.GoodsInfo .Fields li.Code{
		color: rgba(0,0,0,0.25);
	}
	.GoodsInfo .Fields li h1{
		font-size: 1.4rem;
		flex: 1;
	}
	.GoodsInfo .Fields li label{
		display: block;
		width: 70px;
	}

	.GoodsInfo .Fields li div{
		flex: 1;
		flex-wrap: wrap;
		
	}
	.GoodsInfo .Fields li div.Attrs{
		display: flex;
		flex-wrap: wrap;
	}
	.GoodsInfo .Fields li div.Attrs em{
		border: 1px solid rgba(0,0,0,0.08);
		padding: 5px 5px;
		margin-right: 5px;
		margin-bottom: 5px;
		cursor: pointer;
		border-radius: 5px;
	}
	.GoodsInfo .Fields li div.Attrs em.On,.GoodsInfo .Fields li div.Attrs em:hover{
		background-color: rgba(23,134,65,1);
		color: #FFFFFF;
	}
	.GoodsInfo .Fields li div.Attrs em.Disable{
		background-color: rgba(0,0,0,0.2);
		color: rgba(0,0,0,0.4);
	}
	.GoodsInfo .Fields li .Favorite{
		border-radius: 10px;
		color: rgba(0,0,0,0.4);
		cursor: pointer;
		height: 25px;
		line-height: 25px;
		margin-left: 10px;
	}
	.GoodsInfo .Fields .Price{
		font-size: 1.5rem;
		color: rgba(23,134,65,1);
	}

	.Detail{
		width: 100%;
		background-color: #FFFFFF;
	}
	.Detail .Nav{
		padding: 20px;
		border-bottom: 1px solid rgba(0,0,0,0.06);
		font-size: 1.2rem;
		color: #999999;
	}
	.Detail .Nav span{
		padding: 0px 10px;
		color: #999999;
	}
	.Detail .Nav em{
		color: #999999;
		cursor: pointer;
	}
	.Detail .Nav em.On,.Detail .Nav em:hover{
		color: #555555;
	}
	.Detail ul.Show{
		width: 70%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	.Detail ul.Show img{
		width: 100%;
		display: block;
	}
	
	.Props{
		padding-bottom: 30px;
	}
	.Props h3{
		padding-left: 20px;
	}
	.Props ul{
		text-align: left;
		padding-left: 20px;
		margin-bottom: 10px;
	}
	.Props ul li{
		width: 100%;
		display: flex;
		padding: 5px 0px;
	}
	.Props ul li span{
		display: inline-block;
		width: 160px;
		color: rgba(0,0,0,0.4);
	}
	.Props ul li span.ustar{
		flex: 1;
	}
	.Props ul li span.utime{
		text-align: right;
	}
	.Props ul p{
		background-color: rgba(0,0,0,0.04);
		padding: 20px;
	}
	
	.GoodsList{
		background-color: #FFFFFF;
		width: 1280px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.GoodsList .More{
		padding: 40px 0px;
		width: 100%;
		display: block;
		text-align: center;
	}
	.GoodsList .More span{
		width: 200px;
		text-align: center;
		height: 30px;
		line-height: 30px;
		border-radius: 15px;
		border:1px solid #D30701;
		display:inline-block;
		color:rgba(23,134,65,1);
		cursor: pointer;
	}
	.GoodsList .More span:hover{
		color:#ffffff;
		background:rgba(23,134,65,1);
	}
	.GoodsOne{
		width: 216px;
		padding: 30px 20px;
		height: 280px;
		text-align: center;
		position: relative;
		border-bottom: 1px solid rgba(0,0,0,0.06);
	}
	.GoodsOne .BR{
		position: absolute;
		height: 100%;
		width: 1px;
		background-color: rgba(0,0,0,0.06);
		right: 0px;
		top: 0px;
	}
	.GoodsOne:hover{
		cursor: pointer;
	}
	.GoodsOne img{
		width: 200px;
		max-height: 200px;
	}
	.GoodsOne li{
		overflow : hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: #666666;
		margin-top: 5px;
		text-align: left;
	}
	.GoodsOne .Price{
		position: absolute;
		height: 20px;
		left: 20px;
		bottom: 30px;
		color: rgba(23,134,65,1);
		font-size: 12px;
	}
	.GoodsOne .Price em{
		font-size: 1.4rem;
	}
	.GoodsOne .Compare{
		position: absolute;
		height: 20px;
		right: 20px;
		bottom: 25px;
		width: 50px;
		height: 20px;
		line-height: 20px;
		border-radius: 3px;
		border: 1px solid rgba(23,134,65,1);
		color: rgba(23,134,65,1);
		cursor: pointer;
	}
	.GoodsOne .Compare:hover{
		background-color: rgba(23,134,65,1);
		color: #FFFFFF;
	}
	.GoodsOne .Cart{
		position: absolute;
		height: 20px;
		right: 20px;
		top: 30px;
		width: 120px;
		height: 20px;
		line-height: 20px;
		border-radius: 3px;
		border: 1px solid rgba(23,134,65,1);
		color: rgba(23,134,65,1);
		cursor: pointer;
		background-color: rgba(255,255,255,0.8);
		display: none;
	}
	.GoodsOne:hover .Cart{
		display: block;
	}
	
	
	.ShopTitle{
		height: 120px;
		position: relative;
	}
	.ShopTitle .Bg{
		width: 100%;
		height: 120px;
		background-color: rgba(23,134,65,0.06);
		position: absolute;
		text-align: center;
	}
	.ShopTitle .Bg img{
		height: 120px;
	}
	
	.ShopTitle .Title{
		position: absolute;
		width: 100%;
		height: 120px;
	}
	.ShopTitle .Title ul{
		width: 1280px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
	}
	.ShopTitle .Title .Logo{
		cursor: pointer;
	}
	.ShopTitle .Title .Logo img{
		height: 70px;
		width: 70px;
		border-radius: 35px;
		border:2px solid rgba(255,255,255,0.4)
	}
	
	.ShopTitle .Title .Info{
		margin-left: 10px;
		flex: 1;
	}
	.ShopTitle .Title .Info h2{
		color: #FFFFFF;
		margin: 0px;
		padding: 0px;
		font-size: 25px;
		cursor: pointer;
	}
	.ShopTitle .Title .Info li{
		color: rgba(255,255,255,0.7);
		max-width: 800px;
		cursor: pointer;
	}
	
	.RightAct{
		width: 250px;
		text-align: right;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
	.ShopTitle .Title .ShopSearch{
		display: flex;
		margin-top: 10px;
		width: 100%;
	}
	.ShopTitle .Title .ShopSearch div{
	}
	.ShopTitle .Title .ShopSearch span{
		display: inline-block;
		width: 200px;
		margin-right: 5px;
	}
	
	.ShopSearchInput{
		height: 25px;
		line-height: 25px;
		width: 100px;
		padding: 0px 20px;
		background-color: rgba(255,255,255,0.3);
		border-color: rgba(255,255,255,0.4);
		border-style: solid;
		border-width: 1px;
		color: #FFFFFF;
	}
	.ShopSearchBTn{
		height: 27px;
		line-height: 27px;
		width: 100px;
		padding: 0px 10px;
		background-color: rgba(23,134,65,0.6);
		border-width: 0px;
		color: #FFFFFF;
		cursor: pointer;
	}
	
	.ShopName{
		height: 25px;
		line-height: 25px;
		border: 1px solid rgba(23,134,65,0.1);
		border-radius: 13px;
		padding: 0px 10px;
		display: flex;
		align-items: center;
		color: rgba(23,134,65,0.9);
		background-color: rgba(23,134,65,0.1);
	}
	.ShopName:hover{
		background-color: rgba(23,134,65,1);
		color: #FFFFFF;
	}
</style>
